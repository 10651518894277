import { useNewRxFlow as getISNewRxFlow }from "../utils/useNewRxFlow"
import { useNewRxFlowDesktop as getIsNewRxFlowDesktop } from "../utils/useNewRxFlowDesktop";

export const getHubblePricing = (type) => {
  const pricing = {
    hydro: {
      firstPrice: "4.99",
      subPrice: "24.99",
      percentOff: "80",
    },
    skyhy: {
      firstPrice: "9.99",
      subPrice: "29.99",
      percentOff: "66",
    },
    priceGroup: null,
  }

  if (type === "default") return pricing

  if (type === "one") {
    pricing.hydro.firstPrice = "9.99"
    pricing.hydro.percentOff = "60"
    pricing.skyhy.firstPrice = "17.99"
    pricing.skyhy.percentOff = "40"
    pricing.priceGroup = "first"
  }
  else if (type === "two"){
    pricing.hydro.subPrice = "26.99"
    pricing.hydro.percentOff = "80"
    pricing.skyhy.subPrice = "32.99"
    pricing.skyhy.percentOff = "70"
    pricing.priceGroup = "second"
  }
  else if (type === "three") {
    pricing.hydro.firstPrice = "6.99"
    pricing.hydro.percentOff = "72"
    pricing.skyhy.firstPrice = "11.99"
    pricing.skyhy.percentOff = "60"
    pricing.priceGroup = "third"
  }
  else if (type === "four") {
    pricing.hydro.firstPrice = "6.99"
    pricing.skyhy.firstPrice = "11.99"
    pricing.hydro.percentOff = "74"
    pricing.hydro.subPrice = "26.99"
    pricing.skyhy.subPrice = "32.99"
    pricing.skyhy.percentOff = "63"
    pricing.priceGroup = "fourth"
  }

  return pricing
}

export const hubbleClassic = {
  trialPeriod: 10,
  trialPeriodPairs: 10,
  newTrialPeriodStartDate: new Date("2024-08-26T00:00:00.000+00:00"),
}

export const examFaq = [
  {
    question: "What do I need to take the online vision test?",
    answer:
      "<p>Good news! You probably have everything you need right in your home.</p><ul><li>10 feet of space</li><li>A smartphone, tablet, or computer</li><li>Your prescription for your current correction</li></ul><p>And remember, you need to wear your current lenses throughout the online vision test.</p>",
  },
  {
    question: "How do I know if I can take the test for free?",
    answer:
      "<p>There are just a few requirements to qualify for the free online vision tests for contacts. To be eligible for the online vision test, you must:</p><ul><li>Reside in a U.S. state where our test is currently activated (view a list of active states <a href='/exam#visibly-states'>here</a>)</li><li>Be between the ages of 18-55</li><li>Have no health conditions or take any medication that may impact your vision</li><li>Be a Hubble customer with an upcoming expiring Hubble contact lens prescription</li></ul>",
  },
  {
    question: "When can I get the online vision test?",
    answer:
      "<p>If you need to renew your Hubble contacts prescription, this test is for you. Best part? You can take it right now, right from home—no eye doctor appointment needed.</p><p>The test evaluates the visual acuity in your current correction and sends the results for review by a licensed eye doctor in your state who writes a new prescription.</p>",
  },
  {
    question: "Who is the vision test made for?",
    answer:
      "<p>This test is for people whose vision has not changed, have recently completed a comprehensive eye exam, and are looking to renew an expiring Hubble prescription.</p><p>If the test detects that your vision has changed, your prescription will not be renewed. And we recommend that you see an in-person doctor.</p><p>If this is your first prescription, you will need to consult an eye care professional for an eye examination.</p><p><i>This test does not replace an initial eye examination. It does not perform or replace a comprehensive eye examination, nor does it assess eye health. Consult an eye care professional for a comprehensive eye examination yearly or any time you are experiencing pain or discomfort. This test is not intended to diagnose, treat, mitigate, or cure disease.</i></p>",
  },
  {
    question: "How does the online vision test work?",
    answer:
      "<p>If you’re eligible for the free online vision test for contacts, we’ll email you to let you know it’s time to renew your contact prescription online. The email will direct you to our website where you can take the test right from home—all you need is your smartphone, tablet, or computer. Next, a licensed eye doctor will review the results and write your new prescription. Once your new prescription is confirmed, we’ll send it to you via email and add it to your subscription. It’s that easy.</p>",
  },
  {
    question: "How long does it take to complete the vision test?",
    answer:
      "<p>Most people complete our online test in 10 minutes or less! 90% of prescriptions from our licensed doctors are delivered within 24 hours.</p>"
  },
  {
    question: "How often should I take an online eye exam/vision test?",
    answer:
      "<p>A majority of contact lens prescriptions expire within one to two years following the data of your last eye exam or vision test, so you’ll want to make time to take the test prior to your prescription’s expiration date! You must possess a valid prescription to order or start a subscription with Hubble contacts.</p> </br> Keep in mind that this test is for people whose vision has not changed, have recently completed a comprehensive eye exam, and are looking to renew an expiring Hubble prescription. If you do not fall within these categories, use Hubble’s find a doctor tool above to locate an eye care professional near you. </p>"
  },
]

export const contactsFaq = [
  {
    question: "What are your contact lenses made of?",
    answer:
      "<p>Our contact lenses are made of methafilcon-A hydrogel material with 55% water content, UV protection, and a thin edge.</p>",
  },
  {
    question: "Are Hubble contact lenses FDA-approved?",
    answer:
      "<p>Yes, our lenses have been FDA approved for sale in the US for over a decade.</p>",
  },
  {
    question: "Can teenagers wear Hubble lenses?",
    answer:
      "<p>Our lenses are approved for use for anyone age 18+, who has a valid prescription.</p>",
  },
  {
    question: "Do you sell bi-weekly or monthly lenses?",
    answer:
      "<p>We only sell daily lenses because wearing a new lens every day is the safest and most comfortable way to wear contacts.</p>",
  },
  {
    question: "Can I use water or contact lens solution to clean or store my Hubble contact lenses?",
    answer:
      "<p>No! Hubble lenses are daily lenses, designed for individual use. Please do not attempt to wear them for more than one day.</p>",
  },
  {
    question: "Do the lenses come with an eye exam?",
    answer:
      "<p>No, the Classic Lenses by Hubble do not include professional services such as an eye exam. If you’re looking for the easiest way to renew your contact lens subscription, explore Hubble’s <a href='/vision-tests'>FREE vision tests</a>!</p>",
  }
]


export const hydroFaq = [
  {
    question: "What is the difference between Hydro by Hubble and other daily contact lenses sold by Hubble",
    answer:
      `<p>Our Hydro contact lenses are made from Hioxifilcon A and feature a higher water content. The materials mimic and bind with natural tears, designed to lock in moisture and quickly rehydrate your eyes. Plus, our hygienic touch technology packaging ensures minimal contact with your lenses and is the world’s slimmest.</p><p>SkyHy by Hubble Contacts have increased oxygen permeability for enhanced breathability and are manufactured with doctor-recommended Silicone Hydrogel.</p><p>Classic by Hubble Contacts, our original daily disposable lenses contain 55% water content and are designed for clear vision without sacrificing all-day comfort. As always, a ${hubbleClassic.trialPeriod}-day starter pack is just $1.</p><p>All of Hubble’s daily lenses are FDA-approved, affordable (you always receive a discounted first order!) and conveniently available through our subscription model. . Compare our contacts and learn more about which customizable option is right for you.</p>`,
  },
  {
    question: "Are Hubble contact lenses FDA-approved?",
    answer:
      "<p>Yes, our lenses have been FDA approved for sale in the US for over a decade.</p>",
  },
  {
    question: "Can teenagers wear Hubble lenses?",
    answer:
      "<p>Our lenses are approved for use for anyone age 18+, who has a valid prescription.</p>",
  },
  {
    question: "Do you sell bi-weekly or monthly lenses?",
    answer:
      "<p>We only sell daily disposable contact lenses because wearing a new pair each day is the safest and most comfortable way to wear contacts.</p>",
  },
  {
    question: "Can I use water or contact lens solution to clean or store my Hubble contact lenses?",
    answer:
      "<p>No! Hubble lenses are daily lenses, designed for individual use, and to be disposed of after a single wear. Please do not attempt to wear them for more than one day.</p>",
  },
  {
    question: "Does Hubble sell contacts for dry eyes?",
    answer:
      "<p>Yes, Hubble is the best place to order contact lenses online for dry eyes! All of Hubble’s lenses are daily disposable single-vision soft contact lenses and feature greater than 50 percent water content, suitable for those with chronic dry eyes. In addition, Hydro by Hubble Contacts are made with tear-bonding materials that support natural eye hydration, and SkyHy by Hubble Contacts are silicone hydrogel lenses designed for maximum oxygen permeability and all-day breathability.</p><p>Want to learn more? You can <a href='/contact-lenses/hubble'>compare our contacts</a> to discover the best option for your needs.</p>",

  },

]

export const skyhyFaq = [
  {
    question: "What are the differences between SkyHy, Hydro, and Classic Hubble contact lenses?",
    answer:
      "<p>Great question! Let’s start with the similarities: All of Hubble’s daily lenses are FDA-approved, offered at low prices, and ship for free directly to your door, as part of our customizable subscription model. You can compare our contacts to find the right option for you.</p><p>If you’re looking for a little more detail, our Hydro by Hubble contact lenses are made from Hioxifilcon A and feature a higher water content. The materials mimic and bind with natural tears, designed to lock in moisture and quickly rehydrate your eyes. Plus, our hygienic touch technology packaging ensures minimal contact with your lenses and is the world’s slimmest.</p><p>SkyHy is the newest member of the Hubble family. Our most breathable contact lenses yet are made with silicone hydrogel, and feature increased oxygen permeability for enhanced comfort and special flexible material that keeps moisture locked and your eyes naturally hydrated throughout the day.</p>",
  },
  {
    question: "Are Hubble contact lenses FDA-approved?",
    answer:
      "<p>Yes, our lenses have been FDA approved for sale in the US for over a decade.</p>",
  },
  {
    question: "Can teenagers wear Hubble lenses?",
    answer:
      "<p>Our lenses are approved for use for anyone age 18+, who has a valid prescription.</p>",
  },
  {
    question: "Do you sell bi-weekly or monthly lenses?",
    answer:
      "<p>We only sell daily lenses because wearing a new lens every day is the safest and most comfortable way to wear contacts.</p>",
  },
]




export const glassesFaq = [
  {
    question: "Can I order multiple pairs of glasses with different prescriptions?",
    answer:
      "<p>Yes, absolutely! You can add as many pairs of glasses (and sunglasses) to your cart before you check out.</p>",
  },
  {
    question: "Where are Hubble’s glasses made?",
    answer:
      "<p>Our custom Hubble frames are proudly designed in-house in NYC and hand-assembled in Wisconsin. We custom-cut, polish, and mount lenses into their frames before shipping them directly to you. Our Hubble-approved frames, while not developed in-house, are thoughtfully sourced from a family-owned, top-level manufacturer in the Chicagoland area.</p>",
  },
  {
    question: "What type of lenses do you offer?",
    answer:
      "<p>For eyeglasses, we offer polycarbonate, 1.67 high-index, and blue light-filtering lenses.</p>",
  },
  {
    question: "I need thin lenses with my prescription. Do you offer them?",
    answer:
      "<p>Yes, we offer 1.67 high-index lenses, which are recommended for anyone with a strong prescription (greater than or equal to +4.0 or -4.0 power). After you select your preferred frame, you can select a 1.67 high-index lens during the checkout process.</p>",
  },
  {
    question: "What's the best way to clean my lenses?",
    answer:
      "<p>You can clean your lenses with warm water and a small drop of mild dish soap. Then, dry with a clean, soft cotton cloth, like the microfiber lens cloth that came with your purchase. You can also use the Frames by Hubble lens cloth to wipe off light smudges on the regular.</p>",
  },
]



export const sunglassesFaq = [
  {
    question: "Can I order multiple pairs of glasses with different prescriptions?",
    answer:
      "<p>Yes, absolutely! You can add as many pairs of glasses (and sunglasses) to your cart before you check out.</p>",
  },
  {
    question: "Where are Hubble’s glasses made?",
    answer:
      "<p>Our custom Hubble frames are proudly designed in-house in NYC and hand-assembled in Wisconsin. We custom-cut, polish, and mount lenses into their frames before shipping them directly to you. Our Hubble-approved frames, while not developed in-house, are thoughtfully sourced from a family-owned, top-level manufacturer in the Chicagoland area.</p>",
  },
  {
    question: "What type of lenses do you offer?",
    answer:
      "<p>We use the best polarized polycarbonate lenses. Polarized lenses differ from standard tinted lenses in that they are superior at blocking glare and UV rays. In fact, they actually block 100% UVA and UVB rays!</p>",
  },
  {
    question: "I love my Hubble Frame, but it doesn't fit quite right. What should I do?",
    answer:
      "<p>Most issues can be fixed with a quick adjustment at your local optical shop:</p><ul><li>The frames are too tight or loose behind your ears</li><li>The temples are too long or too short</li><li>The temples are too tight or loose at the side of your face</li><li>The frames are crooked or slide down your face</li></ul>",
  },
  {
    question: "What's the best way to clean my lenses?",
    answer:
      "<p>You can clean your lenses with warm water and a small drop of mild dish soap. Then, dry with a clean, soft cotton cloth, like the microfiber lens cloth that came with your purchase. You can also use the Frames by Hubble lens cloth to wipe off light smudges on the regular.</p>",
  },
]

export const accessoriesFaq = [
  {
    question: "What eye care accessories does Hubble offer?",
    answer:
      "<p>Hubble is proud to offer a wide range of eye care products and accessories including eye drops, lens, care kits, eye compresses, glasses accessories, specialized contact lens solutions and more. We’ve done the work of thinking through just about everything you need to keep your glasses and contact lenses in perfect condition and compiled them here!</p>",
  },
  {
    question: "Does Hubble manufacture eyeglasses and eye care accessories?",
    answer:
      "<p>All the eye care accessories you’ll find on this site are from our trusted partners and hand selected by the Hubble team. Taking care of your holistic vision health has never been easier, no matter what you’re looking for. Shop products from trusted industry-leading brands including Lumify, Avenova, Biotrue, Ocuvite and OptiPlus</p>",
  },
  {
    question: "What accessories come standard with Hubble Contact Lenses, Eyeglasses and Sunglasses?",
    answer:
      "<p>Each pair of Hubble <a href='/glasses'>Eyeglasses</a> and <a href='/sunglasses'>Sunglasses</a> include a slim, sturdy, tri-fold case and a microfiber cloth for the occasional polish. If you’re looking to purchase additional specialized accessories you can count on, you’ve come to the right place!</p>",
  },
  {
    question: "Do you offer free shipping?",
    answer:
      "<p>Yes! All of our eye care accessories always ship free. If you have issues or questions about any of the products offered on our site, please <a href='/contact-us'>reach out to us</a> and we’ll make it right.</p>",
  },
  {
    question: "What's the best way to clean my glasses lenses?",
    answer:
      "<p>You can clean your lenses with warm water and a small drop of mild dish soap. Then, dry with a clean, soft cotton cloth, like the microfiber lens cloth that came with your purchase. You can also use the Frames by Hubble lens cloth to wipe off light smudges on the regular.</p>",
  },
]

export const colorTags = {
  glasses: "orange",
  sunglasses: "green",
  contacts: "purple",
  drops: "green",
  accessories: "blue",
}

export const colorTagsSlider = {
  ...colorTags,
  frames: "orange",
  lenses: "purple",
  hydro: "purple",
  newPrices: "purple",
  astigmatism: "metallic-blue"
}

export const glassesValueProps = [
  {
    image: "Pages/GlassesPDP/No-hassle-returns.png",
    label: "No-hassle returns",
  },
  {
    image: "Pages/GlassesPDP/Customer-care.png",
    label: "Customer care 7 days a week",
  },
  {
    image: "Pages/GlassesPDP/Free-shipping.png",
    label: "Free shipping",
  },
  {
    image: "Pages/GlassesPDP/Easy-convenient.png",
    label: "Easy and convenient",
  },
  {
    image: "Pages/GlassesPDP/Hand-assembled.png",
    label: "Hand-assembled with exacting care",
  },
]

export const accessoryValueProps = [
  {
    image: "Pages/AccessoryPDP/no-returns.png",
    label: "No-hassle returns",
  },
  {
    image: "Pages/AccessoryPDP/cx.png",
    label: "Customer care 7 days a week",
  },
  {
    image: "Pages/AccessoryPDP/free-shipping.png",
    label: "Free shipping",
  },
  {
    image: "Pages/AccessoryPDP/easy.png",
    label: "Easy and convenient",
  },
  {
    image: "Pages/AccessoryPDP/hand-selected-heart.png",
    label: "Hand-selected by the Hubble Team"
  },
]

export const hubbleValueProps = (noFda) => {
  const isNewRx = getISNewRxFlow()
  const isNewRxDesktop = getIsNewRxFlowDesktop()

  const defaultValues = [
    {
      image: "Pages/ContactsPDP/Start-for-$1.png",
      label: "First order discount",
    },
    {
      image: "Pages/ContactsPDP/Never-run-out.png",
      label: "Never run out",
    },
    {
      image: "Pages/ContactsPDP/Personalized-for-you.png",
      label: "Personalized for you",
    },
    {
      image: "Pages/ContactsPDP/No-hassle-policies.png",
      label: "No-hassle policies",
    },
  ]

  const fdaApproved = {
    image: "Pages/ContactsPDP/Fda-approved.png",
    label: "FDA approved",
  }

  if (!noFda && (isNewRx || isNewRxDesktop)) return [...defaultValues, fdaApproved]

  return defaultValues
}

export const sunglassesValueProps = [
  {
    image: "Pages/SunglassesPDP/No-hassle-returns.png",
    label: "No-hassle returns",
  },
  {
    image: "Pages/SunglassesPDP/Customer-care.png",
    label: "Customer care 7 days a week",
  },
  {
    image: "Pages/SunglassesPDP/Free-shipping.png",
    label: "Free shipping",
  },
  {
    image: "Pages/SunglassesPDP/Easy-convenient.png",
    label: "Easy and convenient",
  },
  {
    image: "Pages/SunglassesPDP/Hand-assembled.png",
    label: "Hand-assembled with exacting care",
  },
]

export const glassesWithHubble = [
  {
    image: "Pages/GlassesPDP/Glasses-Quality-First.png",
    title: "Quality First",
    description:
      "High-quality eyeglass lenses at prices you can’t believe; anti-reflective, anti-glare and anti-scratch qualities come standard on every pair of these affordable glasses.",
  },
  {
    image: "Pages/GlassesPDP/Glasses-Comfort.png",
    title: "Comfort Matters",
    description:
      "Durable barrel hinges and padded temple tips for extra comfy cush.",
  },
  {
    image: "Pages/GlassesPDP/Glasses-Personalized.png",
    title: "Personalized Just for You",
    description:
      "Ease your eyes—add blue-light filtering and reader magnification to all of our eyeglasses online.",
  },
  {
    image: "Pages/GlassesPDP/Glasses-Care.png",
    title: "Made with Care",
    description: "Every pair of eyeglasses is made-to-order and hand-assembled.",
  },
]

export const hubbleDifference = [
  {
    image: "Pages/ContactsPDP/Contacts-Calendar.png",
    title: "Personalized for You",
    alt: "A schedule demonstrating the convenience of purchasing prescription contact lenses online with Hubble",
    description:
      "Not everyone needs to refill their daily contacts every 30 days. You decide on the refill frequency that’s right for you.",
  },
  {
    image: "Pages/ContactsPDP/Contacts-Quality-Comfort.png",
    title: "Quality and Comfort",
    alt: "Icon illustrating a pair of daily hydrating contact lenses",
    description:
      "Our disposable contact lenses are designed for all-day comfort with a trim, thin edge, making them extra easy to pop in and out. No futzing necessary.",
  },
  {
    image: "Pages/ContactsPDP/Contacts-Care.png",
    title: "Made with Care",
    alt: "Visual of hydrating contact lenses undergoing Hubble's quality assurance check",
    description:
      "Every set of contact lenses passes a multi-layer inspection that’s super tight and refreshingly thorough.",
  },
]

export const sunglassesWithHubble = [
  {
    image: "Pages/SunglassesPDP/Sunglasses-Quality-First.png",
    title: "Quality First",
    description:
      "High-quality sunglasses at prices you can’t believe; anti-reflective, anti-glare, anti-scratch, hydrophobic and oleophobic coatings come standard on every pair",
  },
  {
    image: "Pages/SunglassesPDP/Sunglasses-Comfort.png",
    title: "Comfort Matters",
    description:
      "With durable barrel hinges and padded temple tips for extra comfy cush",
  },
  {
    image: "Pages/SunglassesPDP/Sunglasses-Care.png",
    title: "Made with Care",
    description: "Every pair of sunglasses is made to order and hand-assembled by one of our team members",
  },
]

export const accessoriesWithHubble = [
  {
    image: "Pages/AccessoryPDP/See-Whole-Picture.png",
    alt: "Eye With Drop Illustration",
    title: "See the Whole Picture",
    description:
      "We’re dedicated to providing you with everything you need to keep your eye health in check. That’s why we’ve selected eye wipes, eye drops and more accessories to keep your glasses and contacts ready to go.",
  },
  {
    image: "Pages/AccessoryPDP/Exceptional-Options.png",
    title: "Exceptional Options, Exceptional Service",
    description:
      "Simplify your eye care regimen with our top-notch vision care accessories and enjoy the exceptional customer service you know and trust when shopping with us.",
  },
  {
    image: "Pages/AccessoryPDP/Hand-Selected.png",
    alt: "Award ribbon",
    title: "Hand-Selected with Care",
    description: "We’ve applied the same care and attention we always do to selecting the providers and brands of eye care accessories you find on our site.",
  },
]


export const sunglassesPcpFaq = [
  {
    question: "Sunglasses for Men and Women: Can Prescription Sunglasses be Ordered Online?",
    answer:
      "<p>All of our high-quality frames by Hubble can be ordered as sunglasses! Plus, every made to order pair comes with polarized lenses that blog 99% of harmful UVA/UVB rays. Simply enter your valid prescription information at checkout and select your lens type, and before you know it, your handcrafted pair will arrive right to your door. Ordering sunglasses online is a convenient way to get the vision care you need and find the perfect pair for your comfort, personality and style. </p>",
  },
  {
    question: "Can I use my insurance to buy prescription sunglasses online?",
    answer:
      "<p>Hubble doesn't deal with insurance providers directly. Still, you can use the receipt Hubble emails you after checkout when applying for reimbursement from your provider. If, for some reason, you don't get a receipt or need another copy, contact Hubble via chat or email at help@hubblecontacts.com, and they can send you an invoice for your insurance provider.<p>"
  },
  {
    question: "How much are Prescription Sunglasses Online?",
    answer:
      "<p>Our Frames by Hubble Prescription Sunglasses start at just $59.99! We bring the same passion and craftsmanship to our sunglasses that we do to our eyeglasses and signature contact lenses. This means that we cut out the middleman to bring you the best deal. Many of our sunglasses are designed  in-house and we sell them directly to consumers (i.e. you). We also have strong partnerships with exceptional manufacturers who can accommodate our priority of keeping prices low and quality high.</p>",
  },
  {
    question: "How do I find the best Sunglasses Style for Me?",
    answer:
      '<p>Frames by Hubble come in a wide variety of frame shapes like classic <a href="/sunglasses/shop-sunglasses-by-frame-shape/round-framed-sunglasses/"> round </a> and trendy <a href="/sunglasses/shop-sunglasses-by-frame-shape/cateye-framed-sunglasses/"> cat-eye </a>, as well as colors and patterns like bold <a href="/sunglasses/?color=tortoise"> tortoise </a> or <a href="/sunglasses/?color=pink"> chic pink </a>. When shopping for sunglasses online, take time to consider your personal style preferences and don’t be afraid to try something new! Shop sunglasses by <a href="/sunglasses/shop-sunglasses-by-frame-shape/?"> frame shape </a> or <a href="/sunglasses/shop-sunglasses-by-face-shape/?"> face shape </a> for the perfect pair to flatter any face. And don’t forget, that any pair of Frames by Hubble can be ordered as sunglasses. <br/> <br/> At Hubble, we believe you should love what you see with our Happiness Guarantee. You can return any unopened boxes or frames within 14 days for a full refund. That’s us looking out for you.</p>',
  },
]

export const classicAdvantages = [
  {
    title: "Daily Contact Lenses",
    description: "Our classic Hubble lenses are FDA-approved, made with methafilcon A hydrogel material, and contain 55% water content.",
    image: "Pages/ContactsPDP/Advantages/Classic-by-Hubble-daily-contact-lenses.png",
    alt: ' Shop for discount prescription contacts online with Hubble. Easily personalize your daily lens subscription and receive our affordable Classic by Hubble lenses delivered right to your door.'
  },
  {
    title: "Start Your Subscription for $1",
    description: "Our transparent pricing means no hidden fees or charges. Take advantage of our first-time order offer and receive your starter pack of lenses for $1, shipping and fees included.",
    image: "Pages/ContactsPDP/Advantages/Classic-by-Hubble-start-your-sub.png",
    alt: ' Shop for discount prescription contacts online with Hubble. Easily personalize your daily lens subscription and receive our affordable Classic by Hubble lenses delivered right to your door.'
  },
  {
    title: "All-Day Comfort",
    description: "Providing both UV protection and crystal clear vision, our classic Hubble contacts are designed for easy insertion and a secure fit.",
    image: "Pages/ContactsPDP/Advantages/Classic-by-Hubble-all-day-comfort.png",
    alt: ' Shop for discount prescription contacts online with Hubble. Easily personalize your daily lens subscription and receive our affordable Classic by Hubble lenses delivered right to your door.'
  },
  {
    title: "Customize Your Cadence",
    description: "Control when and where you receive your lenses and refill packs in our customer portal.",
    image: "Pages/ContactsPDP/Advantages/Classic-by-Hubble-customize-your-cadence.png",
    alt: " Shop for discount prescription contacts online with Hubble. Easily personalize your daily lens subscription and receive our affordable Classic by Hubble lenses delivered right to your door."
  },
];

export const hydroAdvantages = [
  {
    title: "Blink Comfort Edge",
    description: "The contacts’ incredibly thin edges minimize eyelid friction and maximize comfort.",
    image: "Pages/ContactsPDP/Advantages/Hydro-by-Hubble-blink-comfort-edge.png",
    alt: 'A graphic demonstrating the difference between the smooth edge profile of Hydro by Hubble contacts versus traditional lenses'
  },
  {
    title: "Natural Eye Hydration",
    description: "Made with tear-bonding materials that keep your eyes hydrated and comfortable all day.",
    image: "Pages/ContactsPDP/Advantages/Hydro-by-Hubble-maximum-eye-hydration.png",
    alt: 'One Box of hydrating Hydro by Hubble Contact Lenses in water'
  },
  {
    title: "Innovative Packaging",
    description: "Fewer raw materials, less plastic use, and 80% less waste add up to the world’s slimmest contact lens packaging.",
    image: "Pages/ContactsPDP/Advantages/Hydro-by-Hubble-eco-friendly-packaging.png",
    alt: 'A visual comparison of Hydro by Hubble contacts slim packaging versus traditional lenses'
  },
  {
    title: "Hygienic Touch Technology",
    description: "Packaged so that your fingers never touch the inside of the lens.",
    image: "Pages/ContactsPDP/Advantages/Hydro-by-Hubble-hygienic-touch-technology.png",
    alt: 'A hand reaches for a Hydro by Hubble contact lens'
  },
];

export const skyhyAdvantages = [
  {
    title: "High Oxygen Permeability",
    description: "Silicone hydrogel contact lenses allow significantly more oxygen to pass through the lens material and help promote eye health.",
    image: "Pages/ContactsPDP/Advantages/SkyHy-by-Hubble-High-Oxygen-Permeability.jpg",
    alt: 'SkyHy silicone hydrogel contact lenses by Hubble Contacts'
  },
  {
    title: "Soft Flex Wear",
    description: "Flexible material allows the lenses to mold to the curve of your eye, fitting snugly. As one of the softest lenses available, SkyHy by Hubble provides high-level comfort and a gentle touch.",
    image: "Pages/ContactsPDP/Advantages/SkyHy-by-Hubble-Soft-Flex-Wear.jpg",
    alt: 'One breathable silicone hydrogel contact lens SkyHy by Hubble Contact Lenses'
  },
  {
    title: "Smart Fit Design",
    description: "A smart multi-curvature design prevents unwanted movement—even when you blink—and promotes tear exchange.",
    image: "Pages/ContactsPDP/Advantages/SkyHy-by-Hubble-Smart-Fit-Design.jpg",
    alt: 'Skyhy by Hubble Contact Lenses are made of breathable silicone hydrogel'
  },
  {
    title: "Natural Eye Hydration",
    description: "With special surface technology and lens curvature that promotes wettability, SkyHy lenses keep moisture locked in for more comfortable, hydrated, all-day wear.",
    image: "Pages/ContactsPDP/Advantages/SkyHy-by-Hubble-Natural-Eye-Hydration.jpg",
    alt: 'SkyHy by Hubble Contact Lenses daily prescription breathable contacts'
  },
];


export const contactsRelatedProducts = [
  {
    productImage1: "https://cdn.shopify.com/s/files/1/1516/5228/products/Avenova-Eye-Compress-PCP-01.png",
    price: 19.99,
    title: "Avenova Eye Compress",
    handle: "avenova-eye-compress",
    type: 'accessory',
  },
  {
    productImage1: "https://cdn.shopify.com/s/files/1/1516/5228/products/Biotrue-Hydration-Boost-33oz-PCP-01.png",
    price: 12.99,
    title: "Biotrue Hydration Boost Eye Drops",
    handle: "biotrue-hydration-boost-new",
    type: 'accessory',
  },
  {
    productImage1: "https://cdn.shopify.com/s/files/1/1516/5228/products/Alaway-.34oz-PCP-01.png",
    price: 12.99,
    title: "Alaway",
    handle: "alaway",
    type: 'accessory',
  },
  {
    productImage1: "https://cdn.shopify.com/s/files/1/1516/5228/products/Ocuvue-Eye-Health-PCP-01.png",
    price: 11.99,
    title: "Ocuvite Eye Health",
    handle: "ocuvite-eye-health",
    type: 'accessory',
  },
]

export const regularAvgProducts = [
  "brand-acuvue-astigmatism",
  "brand-acuvue-moist-astigmatism",
  "brand-acuvue-moist-astigmatism-90",
  "acuvue-oasys-1-day-hydraluxe-astig-30",
  "acuvue-oasys-1-day-hydraluxe-astig-90",
  "brand-acuvue-vita-6pk-astig",
  "bausch-lomb-astig",
  "biotrue-oneday-astig-30"
]
